.main__title_message {
  margin: 40px;
  padding: 0;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: rgba(139, 139, 139, .4)
}

@media screen and (min-width: 320px) {
  .main__title_message {
    font-size: calc((100vw - 769px) / (320 - 769) * (15 - 20) + 20px);
  }
}

@media screen and (min-width: 769px) {
  .main__title_message {
    font-size: 20px;
  }
}