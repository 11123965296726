.footer__block {
  margin: 30px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 534px) {
  .footer__block {
    margin-top: 0;
    flex-direction: row-reverse;
    width: 500px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 747px) {
  .footer__block {
    width: 100%;
  }
}