.header__close-menu {
  position: absolute;
  margin: 14px 14px 0 0;
  padding: 0;
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url(../../../images/header/image-cross.svg);
  transition: .5s;
}

.header__close-menu:hover {
  cursor: pointer;
  opacity: .7;
}

@media screen and (min-width: 769px) {
  .header__close-menu {
    visibility: hidden;
  }
}