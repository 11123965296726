.about-project__stage {
  margin: 0;
  padding: 0;
  width: 100px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3DDC84;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #000000;
}

@media screen and (min-width: 600px) {
  .about-project__stage {
    width: 140px;
    font-size: calc((100vw - 768px)/(320 - 768) * (11 - 14) + 14px);
  }
}

@media screen and (min-width: 768px) {
  .about-project__stage {
    height: 36px;
    width: calc((100vw - 1280px)/(768 - 1280) * (140 - 228) + 228px);
    font-size: 14px;
  }
}

@media screen and (min-width: 1280px) {
  .about-project__stage {
    width: 228px;
  }
}