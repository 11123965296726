.about-project__title {
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 320px) {
  .about-project__title {
    font-size: calc((100vw - 768px)/(320 - 768) * (18 - 20) + 20px);
  }
}

@media screen and (min-width: 768px) {
  .about-project__title {
    font-size: 20px;
  }
}