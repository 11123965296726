.form__question {
  margin: 14px 0 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #8B8B8B;
}

@media screen and (min-width: 450px) {
  .form__question {
    font-size: 14px;
    line-height: 17px;
  }
}