.form__button-back {
  margin: 16px auto 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #4285F4;
  border: none;
  background: none;
  transition: .5s;
}

.form__button-back:hover {
  cursor: pointer;
  opacity: .7;
}

@media screen and (min-width: 320px) {
  .form__button-back {
    font-size: calc((100vw - 768px)/(320 - 768) * (12 - 14) + 14px);
    line-height: calc((100vw - 768px)/(320 - 768) * (15 - 17) + 17px);
  }
}

@media screen and (min-width: 768px) {
  .form__button-back {
    font-size: 14px;
    line-height: 17px;
  }
}