@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.preloader {
  position: fixed;
  top: 50%;
  right: 50%;
  margin: -19px -19px 0 0;
  z-index: 10;
}

.preloader_main {
  position: static;
  width: min-content;
  padding: 20px;
  margin: auto;
}

.preloader__container {
  width: 38px;
  height: 38px;
  position: relative;
}

.preloader__round {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  background: #3DDC84;
  border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
  content: '';
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
}

@media screen and (min-width: 850px) {
  .preloader {
    margin: -25px -25px 0 0;
  }

  .preloader_main {
    margin: auto;
  }

  .preloader__container {
    width: 50px;
    height: 50px;
  }

  .preloader__round {
    width: 50px;
    height: 50px;
  }

  .preloader__round::after,
  .preloader__round::before {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .preloader {
    margin: -30px -30px 0 0;
  }

  .preloader_main {
    margin: auto;
  }

  .preloader__container {
    width: 60px;
    height: 60px;
  }

  .preloader__round {
    width: 60px;
    height: 60px;
  }

  .preloader__round::after,
  .preloader__round::before {
    width: 60px;
    height: 60px;
  }
}


.preloader__round::after {
  background: white;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
  z-index: 10;
  background: #3DDC84;
  ;
  animation: preloader-inside-red 1s ease-in-out infinite;
}