.header__items {
  position: fixed;
  right: -320px;
  top: 0;
  width: 320px;
  min-height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #202020;
  transition: right .3s linear;
  z-index: 10;
  /* border: 1px solid red; */
}

@media screen and (min-width: 769px) {
  .header__items {
    position: static;
    padding: 0;
    width: max-content;
    background: none;
    flex-direction: row;
    min-height: max-content;
  }
}