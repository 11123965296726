.movies-list__button-add {
  margin: 80px 30px 80px;
  padding: 0;
  max-width: 100%;
  height: 36px;
  background: none;
  border: 1px solid #424242;
  border-radius: 6px;
  transition: .5s;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.movies-list__button-add:hover {
  cursor: pointer;
  background-color: #313131;
}

@media screen and (min-width: 747px) {
  .movies-list__button-add {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
  }
}

@media screen and (min-width: 800px) {
  .movies-list__button-add {
    width: 60%;
  }
}

@media screen and (min-width: 1280px) {
  .movies-list__button-add {
    width: 320px;
  }
}