.form__input-error {
  margin: 6px 0 0;
  padding: 0;
  height: 12px;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  transition: .8s;
  visibility: hidden;
  opacity: 0;
  color: #EE3465;
}