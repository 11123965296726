.about-me {
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* border: 1px solid red; */
  margin: auto;
}

@media screen and (min-width: 500px) {
  .about-me {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (min-width: 850px) {
  .about-me {
    padding-left: 70px;
    padding-right: 70px;
  }
}