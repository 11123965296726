.footer {
  padding: 42px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid green; */
}

@media screen and (min-width: 534px) {
  .footer {
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 747px) {
  .footer {
    row-gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 850px) {
  .footer {
    padding-top: 70px;
    padding-left: 70px;
    padding-right: 70px;
  }
}