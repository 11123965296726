.techs__item {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #303030;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.04em;
  border-radius: 10px;
}

@media screen and (min-width: 1000px) {
  .techs__item {
    font-size: 14px;
  }
}