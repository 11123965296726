.popup__message {
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

@media screen and (min-width: 619px) {
  .popup__message {
    font-size: 20px;
    line-height: 30px;
  }
}