.header__link_enter {
  width: 54px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #3DDC84;
  color: #000000;
  /* border: 1px solid red; */
}

@media screen and (min-width: 320px) {
  .header__link_enter {
    width: calc((100vw - 768px)/(320 - 768) * (54 - 76) + 76px);
    height: calc((100vw - 768px)/(320 - 768) * (26 - 32) + 32px);
  }
}

@media screen and (min-width: 768px) {
  .header__link_enter {
    width: 76px;
    height: 32px;
  }
}