.header__logo-link {
  margin: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #3DDC84;
  background-image: url('../../../images/header/logo.svg');
  transition: .5s;
  /* border: 1px solid red; */
}

.header__logo-link:hover {
  cursor: pointer;
  opacity: .5;
}