.popup__close {
  position: absolute;
  display: flex;
  width: 22px;
  height: 22px;
  margin: 0;
  top: -30px;
  right: -30px;
  padding: 0;
  border: none;
  background: none;
  transition: 0.3s;
  /*border: 1px solid red;*/
}

.popup__close:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 618px) {
  .popup__close {
    width: 15px;
    height: 15px;
    top: -26px;
    right: 0;
  }
}