.main__link {
  margin: 0;
  padding: 0;
  width: max-content;
  display: flex;
  text-decoration: none;
  font-family: "Inter", Arial, sans - serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  transition: .5s;
  opacity: .4;
  color: rgba(66, 133, 244, 1);
}

.main__link:hover {
  cursor: pointer;
  opacity: 1;
}

@media screen and (min-width: 320px) {
  .main__link {
    font-size: calc((100vw - 768px) / (320 - 768) * (15 - 20) + 20px);
  }
}

@media screen and (min-width: 769px) {
  .main__link {
    display: none;
  }
}