.form__button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  transition: .8s;
  color: #FFFFFF;
}

.form__button:hover {
  cursor: pointer;
  opacity: .7;
}

.form__button:focus {
  outline: none !important;
  border-radius: 5px;
  border: 1px solid rgba(61, 220, 132, .5);
  padding: 3px;
}

.form__button:last-child {
  margin-top: 16px;
  color: #EE3465
}