.root {
  min-width: 320px;
  max-width: 1280px;
  min-height: 100vh;
  margin: auto;
  display: grid;
  grid-template-rows: min-content auto min-content;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
  /* border: 1px solid red; */
}