.about-project__description {
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-weight: 16px;
  line-height: 1.5;
}

@media screen and (min-width: 320px) {
  .about-project__description {
    font-size: calc((100vw - 768px)/(320 - 768) * (11 - 12) + 12px);
    font-weight: calc((100vw - 768px)/(320 - 768) * (16 - 18) + 18px);
  }
}

@media screen and (min-width: 768px) {
  .about-project__description {
    font-size: 14px;
    font-weight: 20px;
    line-height: 1.5;
  }
}