.techs__description {
  margin: 25px 0 0;
  padding: 0;
  width: 272px;
  text-align: center;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 750px) {
  .techs__description {
    width: 460px;
    margin-top: 22px;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1000px) {
  .techs__description {
    margin-top: 26px;
    font-size: 14px;
    line-height: 20px;
  }
}