.techs__title {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 320px) {
  .techs__title {
    font-size: calc((100vw - 768px)/(320 - 768) * (30 - 50) + 50px);
    line-height: calc((100vw - 768px)/(320 - 768) * (34 - 58) + 58px);
  }
}

@media screen and (min-width: 768px) {
  .techs__title {
    font-size: 50px;
    line-height: 58px;
  }
}