.about-me__name {
  margin: 0 0 20px;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 320px) {
  .about-me__name {
    font-size: calc((100vw - 768px)/(320 - 768) * (30 - 40) + 40px);
    line-height: calc((100vw - 768px)/(320 - 768) * (36 - 40) + 40px);
  }
}

@media screen and (min-width: 768px) {
  .about-me__name {
    font-size: 40px;
    line-height: 40px;
  }
}

@media screen and (min-width: 850px) {
  .about-me__name {
    margin-top: -10px;
    font-size: 50px;
    line-height: 58px;
  }
}