.movies-list__cards {
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(280px, 1fr);
  grid-template-rows: auto;
  row-gap: 16px;
  list-style-type: none;
  /* border: 1px solid blue; */
}

@media screen and (min-width: 500px) {
  .movies-list__cards {
    row-gap: 36px;
  }
}

@media screen and (min-width: 747px) {
  .movies-list__cards {
    column-gap: 30px;
    grid-template-columns: repeat(2, minmax(280px, 0.5fr));
  }
}

@media screen and (min-width: 1028px) {
  .movies-list__cards {
    grid-template-columns: repeat(3, minmax(280px, 0.333fr));
    row-gap: 30px;
    column-gap: 24px;
  }
}