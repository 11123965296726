.footer__title {
  margin: 0;
  padding: 0 0 20px;
  width: 100%;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #8B8B8B;
  border-bottom: 1px solid #424242;
}

@media screen and (min-width: 500px) {
  .footer__title {
    width: 500px;
  }
}

@media screen and (min-width: 747px) {
  .footer__title {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
  }
}