.header__menu {
  display: none;
  height: 22px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px 0 0;
  padding: 0;
  transition: 0.8s;
}

.header__menu:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header__menu {
    display: flex;
  }
}