.about-project__list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 57.5px 30px;
  list-style-type: none;
}

@media screen and (min-width: 750px) {
  .about-project__list {
    width: 100%;
    justify-content: space-between;
  }
}