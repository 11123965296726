.search-form {
  margin: 80px 10px 0;
  padding: 0;
  max-width: 500px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
}

@media screen and (min-width: 520px) {
  .search-form {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 747px) {
  .search-form {
    max-width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 850px) {
  .search-form {
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 70px;
  }
}