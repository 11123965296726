.header__menu-img {
  display: none;
  width: 25px;
  border-bottom: 2.5px solid #FFFFFF;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .header__menu-img {
    display: flex;
  }
}