.form__input {
  margin: 0;
  padding: 0 0 10px;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #424242;
  color: #FFFFFF;
  transition: .5s;
}

.form__input:hover {
  border-bottom: 1px solid rgba(61, 220, 132, 1);
}

.form__input:focus {
  border-bottom: 1px solid rgba(61, 220, 132, .5);
}