.not-found__title {
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 97px;
}

@media screen and (min-width: 320px) {
  .not-found__title {
    font-size: calc((100vw - 768px)/(320 - 768) * (80 - 140) + 140px);
    line-height: calc((100vw - 768px)/(320 - 768) * (97 - 169) + 169px);
  }
}

@media screen and (min-width: 768px) {
  .not-found__title {
    font-size: 140px;
    line-height: 169px;
  }
}