.header__nav {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 14px;
}

@media screen and (min-width: 320px) {
  .header__nav {
    column-gap: calc((100vw - 768px)/(320 - 768) * (14 - 30) + 30px);
  }
}

@media screen and (min-width: 768px) {
  .header__nav {
    column-gap: 30px;
  }
}