.form__link {
  margin: 0 0 0 7px;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  transition: .8s;
  color: #4285F4;
}

.form__link:hover {
  cursor: pointer;
  opacity: .7;
}

@media screen and (min-width: 450px) {
  .form__link {
    font-size: 14px;
    line-height: 17px;
  }
}