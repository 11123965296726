.footer__copyright {
  margin: 30px 0 0;
  padding: 0;
  display: flex;
  min-width: max-content;
  align-items: flex-end;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8B8B8B;
}

@media screen and (min-width: 747px) {
  .footer__copyright {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
  }
}