.portfolio__link-icon {
  margin: 0;
  padding: 0;
  height: 10px;
}

@media screen and (min-width: 320px) {
  .portfolio__link-icon {
    height: calc((100vw - 768px)/(320 - 768) * (10 - 18) + 18px);
  }
}

@media screen and (min-width: 768px) {
  .portfolio__link-icon {
    height: 18px;
  }
}