.header__title {
  margin: 50px 0 80px;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

@media screen and (min-width: 470px) {
  .header__title {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}