.header__links {
  min-height: 100%;
  margin: 144px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: none;
  /* border: 1px solid red; */
}

@media screen and (min-width: 768px) {
  .header__links {
    margin-top: 159px;
  }
}

@media screen and (min-width: 769px) {
  .header__links {
    margin: 0;
    padding: 0;
    min-height: min-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}