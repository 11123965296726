.popup__block {
  margin: 0;
  padding: 30px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #272727;
}

@media screen and (min-width: 619px) {
  .popup__block {
    min-width: 260px;
    padding: 50px;
  }
}