.checkbox__new {
  width: 36px;
  height: 20px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  background: #202020;
  border: 1px solid #424242;
  border-radius: 20px;
  transition: .8s;
}