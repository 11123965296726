.movies-list__image {
  position: relative;
  margin: 0;
  padding: 0;
  height: 168px;
  min-width: 100%;
  max-width: 120px;
  object-fit: cover;
  object-position: center center;
  vertical-align: bottom;
  z-index: 1;
  /* border: 1px solid red; */
}

@media screen and (min-width: 320px) {
  .movies-list__image {
    height: calc((100vw - 520px) / (320 - 520) * (168 - 300) + 300px);
  }
}

@media screen and (min-width: 520px) {
  .movies-list__image {
    height: 300px;
    max-width: 120px;
  }
}

@media screen and (min-width: 747px) {
  .movies-list__image {
    max-height: 190px
  }
}

@media screen and (min-width: 768px) {
  .movies-list__image {
    max-height: calc((100vw - 812px) / (768 - 812) * (190 - 200) + 200px);
  }
}

@media screen and (min-width: 850px) {
  .movies-list__image {
    max-height: calc((100vw - 1028px) / (850 - 1028) * (190 - 255) + 255px);
  }
}

@media screen and (min-width: 1028px) {
  .movies-list__image {
    max-height: calc((100vw - 1280px) / (1028 - 1280) * (170 - 203) + 203px);

  }
}

@media screen and (min-width: 1280px) {
  .movies-list__image {
    max-height: 203px;
  }
}