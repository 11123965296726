.portfolio__link {
  margin: 20px 0 0;
  padding: 0 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.04em;
  text-decoration: none;
  transition: .5s;
  border-bottom: 1px solid #E6E6E6;
  color: #FFFFFF;
}

.portfolio__link:hover {
  cursor: pointer;
  opacity: .5;
}

@media screen and (min-width: 747px) {
  .portfolio__link {
    font-size: 28px;
    line-height: 50px;
  }
}

@media screen and (min-width: 850px) {
  .portfolio__link {
    font-size: 30px;
  }
}