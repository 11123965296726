.footer__links {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  row-gap: 12px;
  /* border: 1px solid red; */
}

@media screen and (min-width: 534px) {
  .footer__links {
    margin-top: 30px;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 30px;
  }
}

@media screen and (min-width: 747px) {
  .footer__links {
    margin-top: 0;
  }
}