.movies-list {
  margin: 0 auto;
  padding: 0 15px 30px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  /* border: 1px solid green; */
}

@media screen and (min-width: 747px) {
  .movies-list {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 850px) {
  .movies-list {
    padding-left: 70px;
    padding-right: 70px;
  }
}