.header__link-login_indent {
  margin-bottom: 50px;
  font-size: 13px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  .header__link-login_indent {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 769px) {
  .header__link-login_indent {
    margin-bottom: 0;
    margin-left: calc((100vw - 1280px)/(770 - 1280) * (130 - 345) + 345px);
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .header__link-login_indent {
    margin-left: 345px;
  }
}