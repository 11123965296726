.form__input-label_profile {
  margin: 0;
  padding-bottom: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 30px;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
}

.form__input-label_profile:first-of-type {
  margin-top: 24px;
  border-bottom: 1px solid #424242;
}

.form__input-label_profile:last-of-type {
  margin-top: 17px;
}

.form__input-label_profile:hover {
  color: rgba(61, 220, 132, 1);
}