.movies-list__play {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .5s;
  opacity: 0;
  z-index: 3;
  /*border: 1px solid green;*/
}

.movies-list__play:hover {
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, .5);
}