.not-found__description {
  margin: 10px 0 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

@media screen and (min-width: 320px) {
  .not-found__description {
    font-size: calc((100vw - 768px)/(320 - 768) * (12 - 16) + 16px);
    line-height: calc((100vw - 768px)/(320 - 768) * (15 - 19) + 19px);
  }
}

@media screen and (min-width: 500px) {
  .not-found__description {
    margin-top: 5px;
  }
}

@media screen and (min-width: 768px) {
  .not-found__description {
    font-size: 16px;
    line-height: 19px;
  }
}