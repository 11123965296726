.form__input-label {
  margin: 6px 0 5px;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #8B8B8B;
}

.form__input-label:first-of-type {
  margin-top: 0;
}