.footer__link {
  margin: 0;
  padding: 0;
  width: max-content;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  transition: .5s;
  color: #FFFFFF;
}

.footer__link:hover {
  cursor: pointer;
  opacity: .5;
}

@media screen and (min-width: 747px) {
  .footer__link {
    font-size: 13px;
    line-height: 16px;
  }
}