.search-form__form {
  margin: 0 0 45px;
  padding: 0;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .5s;
  /* border: 1px solid red; */
}

.search-form__form:hover {
  opacity: .8;
}

@media screen and (min-width: 747px) {
  .search-form__form {
    flex-direction: row;
    max-width: 100%;
    background-color: #2F2F2F;
    border-radius: 9px;
    margin-bottom: 109px;
  }
}

@media screen and (min-width: 1000px) {
  .search-form__form {
    margin-bottom: 80px;
  }
}