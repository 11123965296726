.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  /* border: 1px solid blue; */
}